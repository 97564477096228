export type Constraints = {
    height: any,
    width: any,
    video: any,
    facingMode?: any,
    audio?: boolean
}

export enum TempRangeStatus {
    EXCURSION = 'EXCURSION',
    IN_RANGE = 'IN_RANGE',
    NOT_APPLICABLE = 'NOT_APPLICABLE',
    UNKNOWN = 'UNKNOWN',
    WAITING_FOR_DATA = 'WAITING_FOR_DATA',
    OK = 'OK',
    NO_DATA = 'NO_DATA',
    FAILED_TEMPERATURE= 'FAILED_TEMPERATURE',
}

export interface ContainerPicture {
    attachmentUrl: string,
    changedBy: string,
    changedOn: string,
    createdBy: string,
    createdOn: string,
    deleted: boolean,
    fileId: string,
    fileSize: number,
    filename: string,
    id: number,
    mimetype: string,
}

export interface Logger {
    changedBy: string,
    changedOn: string,
    container: any,
    createdBy: string,
    createdOn: string,
    deleted: boolean,
    firstTimestamp?: string | null,
    id: number,
    loggerNumber: string,
    loggerTypeCode: string,
    lastMeasuredTemp?: any,
    reservedForUsers?: (null)[] | null,
}

export interface PictureStepUserInput {
    attachmentUrl: string,
    changedBy: string,
    changedOn: string,
    createdBy: string,
    createdOn: string,
    deleted: boolean,
    fileId: string,
    fileSize: number,
    filename: string,
    id: number,
    mimetype: string,
}

export interface Company {
    changedBy: string,
    changedOn: string,
    companyName: string,
    companyNumber: string,
    createdBy: string,
    createdOn: string,
    deleted: boolean,
    id: number,
    website: string,
}

export interface Contact {
    id: string,
    companyId: number,
    companyName: string,
    fullName: {
        firstName: string,
        lastName: string,
    },
    email: string,
    mobilePhone: string,
    businessPhone: string,
    jobCategory: string,
    jobTitle: string,
    userRoles: string[]
}

export interface Country {
    changedBy: string,
    changedOn: string,
    countryIsoAlpha2Code: string,
    countryName: string,
    createdBy: string,
    createdOn: string,
    deleted: boolean,
    id: number,
}

export interface TaxCode {
    currency: string,
    id: number,
    taxCode: string,
}

export interface State {
    changedBy: string,
    changedOn: string,
    createdBy: string,
    createdOn: string,
    deleted: boolean,
    id: number,
    stateIsoAlpha2Code: string,
    stateName: string,
}

export interface Choice {
    choiceIdentifier?: string,
    choiceLabel?: string,
    partNumber?: string,
    negativeChoice?: boolean,
    negativeSeverity?: number,
    technicalDrawingName?: string,
    jumpToSummary?: boolean,
    goToStep?: string,
}

export interface Step {
    includeInReport?: boolean,
    isCommentable?: boolean,
    isNestedStep?: boolean,
    isRequired?: boolean,
    stepName?: string,
    stepTitle?: string,
    stepType?: string,
    stepSubtitle?: string | null,
    choices?: Choice[],
    validationRegEx?: string,
    validationErrorMessage?: string,
    helpText?: string,
}

export interface ProgressGroup {
    progressGroup: number,
    steps?: Step[] | null,
}

export interface LoggerContainerInformationEntity {
    exchangeDocumentations?: (null)[] | null,
    exchangeTimestamp: string,
    id: number,
    isCurrentLogger: boolean,
    logger: Logger,
    loggerPosition: string,
}

export interface Location {
    addressCategory?: string[] | null,
    addressLine1: string,
    addressLine2: string,
    addressLine3: string,
    addressMainCategory?: string[] | null,
    attachments?: null[] | null,
    bolAwbInstructions?: null[] | null,
    changedBy: string,
    changedOn: string,
    city: string,
    company: Company,
    contacts?: Contact[] | null,
    country: Country,
    createdBy: string,
    createdOn: string,
    defaultTaxCode?: TaxCode[] | null,
    deleted: boolean,
    hasBulkInvoice: boolean,
    hasCombinedInvoice: boolean,
    id: number,
    isApprovedSubContractor: boolean,
    isMainAddress: boolean,
    isShippingLocation: boolean,
    isSyncedWithAbacus: boolean,
    latitude: number,
    locationName: string,
    locationNumber: string,
    locationType: string,
    longitude: number,
    paymentTerms: string,
    requiresTruckDetails: boolean,
    serviceCenterPlannedStartDate: string,
    state: State,
    timeZone: string,
    zip: string,
}

export type SummaryInfo = {
    personInCharge: string,
    orderEntered: string,
    orderComment: string,
    laneNumber: string,
    pharmaCompany: string,
}

export type Polyline = {
    path: [Location, Location],
    stepStatus?: string,
}

export type Marker = {
    position: Location,
    numberInPosition?: number,
}

export interface ContainerType {
    applicableTempRange?: (string)[] | null,
    changedBy: string,
    changedOn: string,
    containerCode: string,
    containerGeneration: string,
    containerModel: string,
    containerModelExternalC: string,
    containerModelExternalDry: string,
    containerModelInternalC: string,
    containerModelInternalDry: string,
    containerPicture: ContainerPicture,
    containerVolume: string,
    createdBy: string,
    createdOn: string,
    defaultLoggerType: string,
    defaultTempRange: string,
    deleted: boolean,
    extDimHeight: number,
    extDimLength: number,
    extDimWidth: number,
    extStorageRangeEnd: number,
    extStorageRangeStart: number,
    extTempRangeEnd: number,
    extTempRangeStart: number,
    id: number,
    intDimHeight: number,
    intDimLength: number,
    intDimWidth: number,
    intTempRangeEnd: number,
    intTempRangeStart: number,
    isInOperationalUse: boolean,
    isPrototype: boolean,
    isQuotable: boolean,
    maxGrossWeight: number,
    tareWeight: number,
    temperatureRange: string,
    toleranceRange: number,
}

export enum AppMenuItemType {
    ROUTE,
    PARENT,
    INFO
}

export interface AppMenuItem {
    label: string,
    type: AppMenuItemType,
    children?: AppMenuItem[],
    icon?: string,
    route?: string,
    onClick?: Function,
}

export interface Process {
    applicableForLocations?: Location[] | null,
    changedBy?: string,
    changedOn?: string,
    createdBy?: string,
    createdOn?: string,
    deleted?: boolean,
    id?: number,
    process?: string,
    processLabel?: string,
    processName?: string,
    progressGroups?: ProgressGroup[] | null,
    createdOnZoned?: string,
    changedOnZoned?: string,
    location?: any,

}

export interface Container {
    changedBy: string,
    changedOn: string,
    containerDescription: string,
    containerInStatusSince: string,
    containerModelExternal: string,
    containerStatus: string,
    containerStatusChangeDocumentation?: (null)[] | null,
    containerStatusChangeTimestamp: string,
    containerStatusChangedOnUtc: string,
    containerStatusEnriched: string,
    containerStatusEnrichedChangedOnUtc: string,
    containerTempRange: TempRangeStatus,
    containerType: ContainerType,
    createdBy: string,
    createdOn: string,
    defaultLoggerType: string,
    deleted: boolean,
    id: number,
    imeiId: string,
    isInProcess: boolean,
    isRetrofitted: boolean,
    jypId: string,
    lastMeasuredOnUtcAmbient: number,
    lastMeasuredOnUtcInternal: number,
    lastMeasuredTempInternalTimestamp: number,
    lastMeasuredTempAmbient: number,
    lastMeasuredTempInternal: number,
    locationNew: Location,
    loggerContainerInformation?: (LoggerContainerInformationEntity)[] | null,
    loggerExchangedOnUtcAmbient: number,
    loggerExchangedOnUtcInternal: number,
    loggerNumberAmbient: string,
    loggerNumberInternal: string,
    manufacturingTimestamp: string,
    material: string,
    previousContainerStatus: string,
    primaryLoggerType: string,
    requiredLoggerExchange?: (null)[] | null,
    reservedForCompany?: (null)[] | null,
    reservedForLocation?: (null)[] | null,
    reservedForUsers?: (null)[] | null,
    serialNumber: string,
    serviceCenterViewTime: string,
    tempRange: string,
}

export interface EvidenceApproval {
    approvalStatus: string,
    containerStatus: string,
    evidenceApprovalNumber: string,
    id: number,
    palletStatus1: string,
    palletStatus2: string,
    qmApprovalStatus: string,
    sealStatus: string,
    sender: string,
    temperatureCheckRequired: boolean,
    temperatureRangeMax: number,
    temperatureRangeMin: number,
    temperatureMax: number,
    temperatureMin: number,
    temperatureStatus: TempRangeStatus,
    timestampSending: string,
    timestampReceiving: string,
    transportStatus: string,
    temperatureExcursionOn: string,
    containerOpenedOn: string,
}

export interface SelectedAsset {
    container: Container,
    productRelease: EvidenceApproval,
}

export interface Billing {
    "baseLeaseDays": number,
    "baseLeaseUntil": string,
    "baseLeaseUntilTimestamp": string,
    "billedAmount": number,
    "billingComment": string,
    "billingCompanyContacts": any[],
    "billingCurrency": string,
    "changedBy": string,
    "changedOn": string,
    "createdOn": string,
    "deleted": false,
    "expressDays": number,
    "hasBillingCompleted": false,
    "id": number,
    "leaseStartDate": string,
    "leaseStartTimestamp": string,
    "leaseEndDate": string,
    "leaseEndTimestamp": string,
    "outgoingInvoices": any[],
}

export interface OrderStep {
    id?: number,
    stepType?: any,
    stepStatus?: string,
    stepTypeDetailed?: string,
    pickupLocation: Object,
    originAirport: Object,
    destinationAirport: Object,
    deliveryLocation: Object,
    originSeaport: Object,
    destinationSeaport: Object,
    location: Object,
    actualPickup: string,
    actualFlightDeparture: string,
    actualFlightArrival: string,
    actualDelivery: string,
    actualShipDeparture: string,
    actualShipArrival: string,
    actualExecution: string,
    requestedPickup: string,
    requestedFlightDeparture: string,
    requestedFlightArrival: string,
    requestedDelivery: string,
    requestedShipDeparture: string,
    requestedShipArrival: string,
    requestedExecution: string,
}
export interface CargoPackaging {
    currentShipment: any,
    lastMeasuredDoor: string,
    lastMeasuredDoorTimestamp: string,
    lastMeasuredGeolocation: any,
    lastMeasuredGeolocationTimestamp: any,
    lastMeasuredTempAmbient: 4.9,
    lastMeasuredTempAmbientTimestamp: string,
    lastMeasuredTempInternal: 4.8,
    lastMeasuredTempInternalTimestamp: string,
    packagingType: any,
    pairingInformation: any[],
    rentalInformation: any[],
    serialNumber: string,
}
export interface SkyMindProductRelease {
    "approvalStatus": string,
    "transportStatus": string,
    "palletStatus": string,
    "qmApprovalDetails":
        {
            "qmApprovedOn": string,
            "qmApprovalComment": string,
            "qmApprovedBy": string,
            "qmApprovalStatus": string,
        }[]
    ,
    "palletCode": string,
    "palletStatus2": string,
    "temperatureMax": number,
    "qmApprovedBy": string,
    "temperatureMin": number,
    "temperatureExcursionOn": string,
    "temperatureStatus": TempRangeStatus,
    "sealStatus": string,
    "palletCode2": string,
    "temperatureRangeMin": number,
    "palletCode1": string,
    "qmApprovedOn": string,
    "qmApprovalComment": string,
    "palletStatus1": string,
    "qmApprovalStatus": string,
    "id": number,
    "temperatureRangeMax": number,
    "containerStatus": string,
}
export interface Cargo {
    packaging: CargoPackaging,
    skyMindProductRelease: SkyMindProductRelease
}
export interface SkyMindInfo {
    collectionDropoffPoint: string,
    destinationLocation: string,
    from: string,
    handoverPoint: string,
    leaseEnd: string,
    leaseEndExpected: string,
    leaseStart: string,
    mawbNumber: string,
    orderSteps: any[],
    shipmentEnd: string,
    shipmentStart: string,
    skyMindId: number,
    temperatureRange: string,
    to: string,
}
export interface Order {
    id: number,
    billing: Billing,
    baseLeaseUntil: string,
    containers: Container[],
    containerIcons: string[],
    containerTypes: string,
    containerOpenedOn: string,
    deliveryDate: string,
    isAllApproved: boolean,
    isPending: boolean,
    isWarning: boolean,
    laneNumber: string,
    leaseStartTimestamp: string,
    leaseEndTimestamp: string,
    shipmentNumber: string,
    temperatureRange: string,
    pharmaCompanyName: string,
    personInCharge: string,
    pickupDate: string,
    polylines: Polyline[],
    orderEntered: string,
    orderComment: string,
    orderSteps: OrderStep[],
    marker?: Marker,
    airportInfo: any,
    summaryInfo: SummaryInfo,
    selectedAssets: SelectedAsset[],
    selectedContainers: SelectedAsset[],
    cargo?: Cargo[],
    skyMindInfo?: SkyMindInfo,
    deliveryPointsInfo: any,
    shipmentStart: string,
    shipmentEnd: string,
}
