import React, { useCallback, useMemo, useState } from 'react';
import ReactApexChart from 'react-apexcharts';
import moment from 'moment';
import { useTranslation } from 'react-i18next';
import { ApexOptions } from 'apexcharts';
import { momentAddOffset, utcDateToEpoch } from 'app/utils/tools';
import { useStyles } from './TemperatureChart.style';
import useScreenSize from '../../hooks/useScreenSize';
import MobileZoomBar from '../steps/TemperatureInfo/MobileZoomBar';

type Props = {
    data: any,
    containerType?: string,
    chartMounted: (chart?: any, options?: any) => void,
    excursionOn: string,
    leaseStartTimestamp: string,
    leaseEndTimestamp: string,
    shipmentStart: string,
    shipmentEnd: string,
    expectedLease: string,
    labelData: {
        dataTypes: string[],
        loggerTypes: string[],
        positions: string[],
    }
}

const ContainerMinMaxTemp = {
    DF: [-80, -60],
    F: [-25, -15],
    C: [2, 8],
    CRT: [15, 25],
};

const getExcursionLine = (excursionOn: string) => ({
    x: moment(excursionOn, 'DD-MM-YYYY HH:mm:ss')
        .utc(true)
        .add({ minute: moment().utcOffset() })
        .valueOf(),
    borderColor: '#D44848',
    strokeDashArray: 0,
    borderWidth: 2,
    label: {
        style: {
            background: 'none',
        },
        borderWidth: 0,
        offsetY: -10,
        offsetX: 16,
        text: 'Excursion',
    },
});
const DOOR_COLORS = ['#fa19dd', '#fd8fef'];
const DANGER_COLOR = '#EDAE49';
const getDoorLine = (date: string, label = 'OPEN') => ({
    x: moment(date, 'YYYY-MM-DDTHH:mm')
        .utc(true)
        .add({ minute: moment().utcOffset() })
        .valueOf(),
    strokeDashArray: 0,
    borderWidth: 2,
    borderColor: DOOR_COLORS[label === 'OPEN' ? 0 : 1],
});
const getCustomAnnotation = ({
    date,
    label,
    dateMask = 'DD-MM-YYYY HH:mm ZZ',
    offset = 0,
    offsetY = -10,
    dashed = false,
    background = 'white',
    utc = false,
}) => ({
    x: momentAddOffset(moment(date, dateMask).utc(utc)).valueOf(),
    borderColor: '#747474',
    strokeDashArray: dashed ? 2 : 0,
    borderWidth: dashed ? 1 : 2,
    label: {
        style: {
            color: '#747474',
            background,
        },
        offsetX: offset,
        offsetY,
        borderWidth: 0,
        text: label,
    },
});
const minZoomFactor = -0.4;
const maxZoomFactor = 0.48;
const zoomIncrement = 0.05;

const TemperatureChart = ({
    data = [],
    containerType = 'CRT',
    chartMounted = () => {
    },
    excursionOn,
    leaseStartTimestamp,
    leaseEndTimestamp,
    shipmentStart,
    shipmentEnd,
    expectedLease,
    labelData,
}: Props) => {
    const { t } = useTranslation();
    const classes = useStyles();
    const setDateItem = (dateItem: number) => (dateItem < 10 ? `0${dateItem}` : dateItem);
    const [touches, setTouches] = useState(null);
    const [tableViewControl, setTableViewControl] = useState<{
        zoom: number,
        position: number
    }>({
        zoom: 0,
        position: 0,
    });

    const adjustZoom = useCallback((direction = true) => {
        setTableViewControl(prev => ({
            ...prev,
            position: (prev.zoom - zoomIncrement < 0.001) ? 0 : prev.position,
            zoom: Math.min(
                Math.max(
                    prev.zoom + ((direction ? 1 : -1) * zoomIncrement),
                    minZoomFactor,
                ), maxZoomFactor,
            ),
        }));
    }, [setTableViewControl]);
    const adjustDelta = useCallback((direction = true) => {
        setTableViewControl(prev => ({
            ...prev,
            position: Math.min(
                Math.max(prev.position + (direction ? 1 : -1) * 0.05, -1),
                1,
            ),
        }
        ));
    }, [setTableViewControl]);

    const xAnnotations = useMemo(() => {
        const annotations = [];

        if (excursionOn) {
            annotations.push(
                getExcursionLine(excursionOn),
            );
        }
        if (leaseStartTimestamp) {
            annotations.push(getCustomAnnotation(
                {
                    date: leaseStartTimestamp,
                    dateMask: 'DD.MM.YYYY HH:mm:ss ZZ',
                    offset: 17,
                    label: t('LEASE_START'),
                },
            ));
        }
        if (shipmentStart) {
            annotations.push(getCustomAnnotation(
                {
                    date: shipmentStart,
                    dateMask: 'YYYY-MM-DDTHH:mm',
                    offset: 17,
                    label: t('SHIPMENT_START'),
                    utc: true,
                },
            ));
        }
        if (shipmentEnd) {
            annotations.push(getCustomAnnotation(
                {
                    date: shipmentEnd,
                    dateMask: 'YYYY-MM-DDTHH:mm',
                    label: t('SHIPMENT_END'),
                    offset: 14,
                    utc: true,
                },
            ));
        }

        if (expectedLease) {
            annotations.push(getCustomAnnotation(
                {
                    date: expectedLease,
                    dateMask: 'DD.MM.YYYY HH:mm',
                    label: t('EXPECTED_LEASE_END'),
                    offset: 14,
                    background: 'none',
                    utc: false,
                },
            ));
            annotations.push(getCustomAnnotation(
                {
                    date: moment().utc(false).format('DD.MM.YYYY HH:mm'),
                    dateMask: 'DD.MM.YYYY HH:mm',
                    label: t('NOW'),
                    dashed: true,
                    offset: -1,
                    utc: true,
                },
            ));
        }
        if (leaseEndTimestamp) {
            annotations.push(getCustomAnnotation(
                {
                    date: leaseEndTimestamp,
                    dateMask: 'DD.MM.YYYY HH:mm:ss ZZ',
                    label: t('LEASE_END'),
                    offset: 14,
                },
            ));
        }

        return annotations;
    }, [
        excursionOn,
        leaseEndTimestamp,
        leaseStartTimestamp,
        shipmentStart,
        shipmentEnd,
        expectedLease,
    ]);

    const doorAnnotations = useMemo(() => {
        if (!labelData?.dataTypes?.includes('DOOR')) {
            return [];
        }
        const doorIndex = labelData?.dataTypes?.findIndex((dt, i) => dt === 'DOOR'
            && ['UNSPECIFIED', 'NOT_APPLICABLE', 'DOOR'].includes(labelData?.positions?.[i]));

        if (doorIndex === -1) return [];

        return data
            .filter(it => it?.d?.[doorIndex])
            .map(it => ({ date: it?.t, label: it?.d[doorIndex] }))
            .map((door) => getDoorLine(door.date, door.label)).reverse();
    }, [data, labelData]);

    const series = useMemo(() => {
        if (!labelData?.dataTypes?.includes('TEMPERATURE')) {
            return [{
                name: 'Temperature',
                data: new Array(data.length).fill(null),
            }];
        }
        const temperatureIndex = labelData?.dataTypes?.findIndex((dt, i) => dt === 'TEMPERATURE'
            && labelData?.positions?.[i] === 'INTERNAL');

        if (temperatureIndex === -1) return [];
        return [{
            name: t('TEMPERATURE.INTERNAL_TEMPERATURE'),
            data: data.map(({ d }) => d?.[temperatureIndex] || null),
        }];
    }, [data, containerType, labelData]);

    const { width, height } = useScreenSize();

    const isMobile = useMemo(() => {
        return width < 960;
    }, [width]);

    const minMax = useMemo(() => {
        if (!isMobile) {
            return {};
        }
        const {
            zoom,
            position,
        } = tableViewControl;

        const [
            start,
            end,
        ] = [
            utcDateToEpoch(data?.[0]?.t),
            utcDateToEpoch(data?.[data?.length - 1]?.t),
        ];

        const length = end - start;
        const zoomedStart = start + length * zoom;
        const zoomedEnd = end - length * zoom;

        return {
            min: zoomedStart + length * (position * (1 - zoom)),
            max: zoomedEnd + length * (position * (1 - zoom)),
        };
    }, [isMobile, tableViewControl]);
    const options = useMemo<ApexOptions>((): ApexOptions => ({
        chart: {
            id: 'basic-bar',
            events: {
                mounted: chartMounted,
            },
            toolbar: {
                tools: {
                    zoomin: !isMobile,
                    zoomout: !isMobile,
                    pan: !isMobile,
                    zoom: !isMobile,
                    download: !isMobile,
                    selection: !isMobile,
                    reset: !isMobile,
                },
            },
        },
        legend: {
            show: true,
            position: 'bottom',
            showForSingleSeries: true,
            customLegendItems: [
                t('OPENED'),
                t('CLOSED'),
                t('ALLOWED_RANGE'),
            ],
            markers: {
                fillColors: [
                    DOOR_COLORS[0],
                    DOOR_COLORS[1],
                    DANGER_COLOR,
                ],

            },
        },
        xaxis: {
            type: 'datetime',
            categories: data?.map(({ t }) => utcDateToEpoch(t)),
            ...minMax,
        },
        stroke: {
            width: 2,
            curve: 'straight',
        },
        annotations: {
            xaxis: [...doorAnnotations, ...xAnnotations],
            yaxis: [
                ...(ContainerMinMaxTemp[containerType]?.[0] ? [{
                    y: ContainerMinMaxTemp[containerType]?.[0],
                    borderColor: DANGER_COLOR,
                    borderWidth: 2,
                }] : []),
                ...(ContainerMinMaxTemp[containerType]?.[1] ? [{
                    y: ContainerMinMaxTemp[containerType]?.[1],
                    borderColor: DANGER_COLOR,
                    borderWidth: 2,
                }] : []),
            ],

        },
        tooltip: {
            shared: true,
            x: {
                formatter: (date) => {
                    const d = new Date(date);
                    const month = setDateItem(d.getUTCMonth() + 1);
                    const day = setDateItem(d.getUTCDate());
                    const hour = setDateItem(d.getUTCHours());
                    const minutes = setDateItem(d.getUTCMinutes());

                    return `${d.getUTCFullYear()}-${month}-${day} ${hour}:${minutes}`;
                },
            },
            y: {
                formatter(value: any): string { // To remove Door part from tooltip
                    return Number.isNaN(value) ? undefined : value;
                },
            },
        },
        yaxis: {
            min: Math.min(ContainerMinMaxTemp[containerType]?.[0],
                Math.min(...series?.[0]?.data || [])) - 2,
            max: Math.max(ContainerMinMaxTemp[containerType]?.[1],
                Math.max(...series?.[0]?.data || [])) * 1.3,
        }
        ,
    }), [
        data,
        containerType,
        xAnnotations,
        doorAnnotations,
        chartMounted,
        series,
        isMobile,
        minMax,
    ]);

    return (
        <>
            <div
                className={classes.chartWrapper}
                onTouchStart={e => setTouches(e?.changedTouches)}
                onTouchEnd={e => {
                    if (tableViewControl.zoom < 0.001) return;
                    const startTouch = touches?.item(0);
                    const endTouch = e.changedTouches?.item(0);

                    if (Math.abs(endTouch.pageX - startTouch?.pageX) > 30) {
                        adjustDelta(endTouch.pageX - startTouch?.pageX < 0);
                    }
                }}
            >
                {
                    isMobile && (
                        <MobileZoomBar
                            classes={classes}
                            onZoomIn={adjustZoom.bind(null, true)}
                            onZoomOut={adjustZoom.bind(null, false)}
                            onLeft={adjustDelta.bind(null, false)}
                            onRight={adjustDelta.bind(null, true)}
                            onReset={setTableViewControl.bind(null, {
                                zoom: 0,
                                position: 0,
                            })}
                            showPan={tableViewControl.zoom > 0.001}
                        />
                    )
                }
                <ReactApexChart
                    type="line"
                    series={series}
                    options={options}
                    width={Math.min(width - 40, 600)}
                    height={Math.min(height - 420, 600)}
                />
            </div>
        </>
    );
};

export default TemperatureChart;
