import React, { useEffect, useMemo, useState } from 'react';

import { useTranslation } from 'react-i18next';
import { dateTimeMaskTimestamp, IMAGES } from 'app/utils/constants';
import { Cargo, Container, Order, TempRangeStatus } from 'app/utils/dataTypes';
import TemperatureChart from 'app/components/TemperatureChart';
import getGlobalAssetUrl from 'app/utils/getGlobalAssetUrl';
import { encodeObjToQuery, trimSensorData } from 'app/utils/tools';
import LoadingSkyCell from 'app/components/LoadingSkyCell';
import useSecureBackendEndpoints from 'app/hooks/useSecureBackendEndpoints';
import moment from 'moment';
import dateToISO from 'app/utils/dateToISO';
import MeasurementCard from '../MeasurementCard';
import ShipmentCard from '../ShipmentCard';
import useStyles from '../TemperatureInfo.style';

const dateRanges = {
    '24h': 1,
    '7d': 7,
    '14d': 14,
};

type Props = {
    order: Order,
    serialNumber: string,
    buttonPressed: string,
    setSensorDataLoading: any,
    sensorDataLoading: boolean,
    expectedLease: any,
    setContainerError: any,
}

const TemperatureInfoContents = ({
    serialNumber,
    order,
    buttonPressed,
    setSensorDataLoading,
    sensorDataLoading,
    expectedLease,
    setContainerError,
}: Props) => {
    const classes = useStyles();
    const { t } = useTranslation();
    const [tempCheckResult, setTempCheck] = useState<TempRangeStatus | null>(null); // No temp check service yet
    const [container, setContainer] = useState<Container>(null);
    const [sensorData, setSensorData] = useState([]);
    const [labelData, setLabelData] = useState(null);
    const {
        FlexibleRequest: secureRequest,
    } = useSecureBackendEndpoints('').requests;

    const [fromDate, toDate] = useMemo<(string | null)[]>(() => {
        if (buttonPressed === 'SHIPMENT') {
            return [
                sensorData?.[0]?.t,
                sensorData?.[sensorData?.length - 1]?.t,
            ];
        } else {
            return [
                dateToISO(moment().utc(false).subtract({ day: dateRanges[buttonPressed] })),
                dateToISO(moment().utc(false)),
            ];
        }
    }, [
        buttonPressed,
        sensorData,
    ]);

    useEffect(() => {
        if (serialNumber) {
            (async () => {
                try {
                    const containerData = await secureRequest(
                        'GET',
                        `/container-info/${serialNumber}`,
                    );
                    const tempCheckResponse = await secureRequest(
                        'GET',
                        `/packagings/${serialNumber}/current-temperature-in-range`,
                    );

                    if (tempCheckResponse?.data) {
                        setTempCheck(tempCheckResponse.data);
                    }
                    if (containerData) {
                        setContainer(containerData?.data);
                    } else {
                        setContainerError(true);
                    }
                } catch (error) {
                    global.console.log(error);
                    setContainer(null);
                    setContainerError(true);
                }
            })();
        } else {
            setContainer(null);
        }
    }, [serialNumber]);

    const cargo = useMemo<Cargo>(
        () => order?.cargo?.find(it => it?.packaging?.serialNumber === serialNumber),
        [order],
    );

    useEffect(() => {
        if (buttonPressed === 'NOW') return;
        if (!serialNumber) return;

        (async () => {
            setSensorDataLoading(true);
            let result;

            if (buttonPressed === 'SHIPMENT') {
                try {
                    result = await secureRequest(
                        'GET',
                        `/shipment/${order.shipmentNumber}/packaging/${serialNumber}/sensor-data?${encodeObjToQuery({
                            dataTypes: ['TEMPERATURE', 'DOOR'],
                            positions: ['INTERNAL'],
                            onlyUntilNow: false,
                        })}`,
                    );
                } catch (e) {
                    setSensorDataLoading(false);
                }
            } else {
                try {
                    result = await secureRequest(
                        'GET',
                    `/packaging/${serialNumber}/sensor-data?${encodeObjToQuery({
                        from: fromDate,
                        to: toDate,
                        dataTypes: ['TEMPERATURE', 'DOOR'],
                        positions: ['INTERNAL'],
                    })}`,
                    );
                } catch (e) {
                    setSensorDataLoading(false);
                }
            }

            setSensorData(result?.data?.data || []);
            setLabelData({
                positions: result?.data?.positions,
                dataTypes: result?.data?.dataTypes,
                loggerTypes: result?.data?.loggerTypes,
            });
            setSensorDataLoading(false);
        }
        )();
    }, [
        buttonPressed,
        order,
        setSensorDataLoading,
    ]);

    const updatedSensorData = useMemo(() => {
        return trimSensorData({
            sensorData,
            leaseStartTimestamp: order?.billing?.leaseStartTimestamp,
            leaseEndTimestamp: order?.billing?.leaseEndTimestamp,
            baseLeaseUntil: order?.billing?.baseLeaseUntilTimestamp,
            shipmentEnd: order?.shipmentEnd,
            from: fromDate,
            to: toDate,
        });
    }, [
        sensorData,
        buttonPressed,
        order?.billing?.leaseStartTimestamp,
        order?.billing?.leaseEndTimestamp,
        order?.billing?.baseLeaseUntilTimestamp,
        fromDate,
        toDate,
    ]);

    if (sensorDataLoading) {
        return (
            <div style={{ height: '100%' }}>
                <LoadingSkyCell />
            </div>
        );
    }
    return (
        <>
            {
                !container && <LoadingSkyCell />
            }
            {
                buttonPressed !== 'SHIPMENT' ? (
                    <div className={classes.topScreen}>
                        <span className={classes.containerText}>{t('CONTAINER')}</span>
                        <span className={classes.serialNumber}>{serialNumber}</span>
                    </div>
                ) : (

                    <ShipmentCard
                        serialNumber={serialNumber}
                        shipmentNumber={order?.shipmentNumber}
                        selectedAsset={cargo}
                        tempCheckResponse={tempCheckResult}
                        buttonPressed={buttonPressed}
                        container={container}
                        fromDate={fromDate}
                        toDate={toDate}
                        fromTooltip={
                            (order?.shipmentStart && 'Shipment Start Date')
                            || (order?.skyMindInfo?.leaseStart && 'Lease Start Date')
                            || 'No Date'
                        }
                        toTooltip={
                            (order?.shipmentEnd && 'Shipment End Date')
                            || (order?.skyMindInfo?.leaseEnd && 'Lease End Date')
                            || 'Expected Lease End Date'
                        }
                    />
                )
            }

            {
                buttonPressed === 'NOW' && container
                    ? (
                        <MeasurementCard
                            tempRangeCheck={tempCheckResult}
                            temperatureRange={container?.tempRange}
                            lastMeasuredTempInternal={container?.lastMeasuredTempInternal}
                            lastMeasuredTempInternalTimestamp={container?.lastMeasuredTempInternalTimestamp}
                        />
                    ) : <></>
            }

            {
                ['24h', '7d', '14d', 'SHIPMENT'].includes(buttonPressed)
                && (sensorData?.length === 0
                    ? (
                        <img
                            className={classes.sleepingImg}
                            src={getGlobalAssetUrl(IMAGES.sleeping)}
                            alt="sleep"
                        />
                    )
                    : (
                        <TemperatureChart
                            labelData={labelData}
                            data={updatedSensorData}
                            containerType={container?.tempRange}
                            chartMounted={() => setSensorDataLoading(false)}
                            excursionOn={cargo?.skyMindProductRelease?.temperatureExcursionOn}
                            leaseEndTimestamp={!order?.shipmentStart && order?.billing?.leaseEndTimestamp}
                            leaseStartTimestamp={!order?.shipmentEnd && order?.billing?.leaseStartTimestamp}
                            shipmentStart={buttonPressed === 'SHIPMENT' ? fromDate : order?.skyMindInfo?.shipmentStart}
                            shipmentEnd={buttonPressed === 'SHIPMENT' ? toDate : order?.skyMindInfo?.shipmentEnd}
                            expectedLease={buttonPressed === 'SHIPMENT'
                                ? expectedLease?.format(dateTimeMaskTimestamp) : null}
                        />
                    ))
            }

        </>
    );
};

export default TemperatureInfoContents;
