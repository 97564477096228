import React from 'react';
import { Classes } from 'jss';
import { useTranslation } from 'react-i18next';
import { TempRangeStatus } from '../../../../../utils/dataTypes';
import getGlobalAssetUrl from '../../../../../utils/getGlobalAssetUrl';
import { ICONS } from '../../../../../utils/constants';

type Props = {
    temperatureStatus: TempRangeStatus,
    buttonPressed: string,
    classes: Classes
}
const TempRangeStatusIcon = (string: TempRangeStatus) => getGlobalAssetUrl(({
    [TempRangeStatus.EXCURSION]: ICONS.cross,
    [TempRangeStatus.IN_RANGE]: ICONS.tickmark_blue,
    [TempRangeStatus.NOT_APPLICABLE]: ICONS.dots_round_grey,
    [TempRangeStatus.UNKNOWN]: ICONS.dots_round_grey,
    [TempRangeStatus.WAITING_FOR_DATA]: ICONS.dots_round_grey,
    [TempRangeStatus.OK]: ICONS.tickmark_blue,
    [TempRangeStatus.NO_DATA]: ICONS.dots_round_grey,
    [TempRangeStatus.FAILED_TEMPERATURE]: ICONS.cross,
})[string] || ICONS.dots_round_grey);

const RangeCheckResult = ({
    temperatureStatus,
    classes,
    buttonPressed,
}: Props) => {
    const { t } = useTranslation();

    const tempString = JSON.parse(JSON.stringify(temperatureStatus));

    global.console.log(tempString);
    return (
        <>
            <span>{t('TEMPERATURE.RANGE_CHECK')}</span>
            <span>
                <img
                    className={classes.icon}
                    src={TempRangeStatusIcon(temperatureStatus)}
                    alt="tempCheck"
                />
                {
                    t(buttonPressed === 'SHIPMENT'
                        ? temperatureStatus
                        : tempString.value)
                }
                { global.console.log(tempString.value) }
            </span>
        </>
    );
};

export default RangeCheckResult;
